import { IconType } from "react-icons";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Flex,
  FlexProps,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  RiSettings5Line,
  RiProfileLine,
  RiArrowGoBackFill,
} from "react-icons/ri";
import { ReactNode } from "react";

export function SettingsMenu() {
  const navigate = useNavigate();
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<RiSettings5Line />}
        variant="outline"
      />
      <MenuList>
        <MenuItem
          icon={<RiProfileLine />}
          onClick={() => {
            navigate("/settings");
          }}
        >
          Profile
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export type NavMenu = Array<{
  name: string;
  icon?: IconType;
  to?: string;
}>;

interface NavigationLinkProps {
  to: string;
  children: ReactNode;
}

function NavigationLink(props: NavigationLinkProps) {
  const { to, children } = props;

  return (
    <Link
      to={to}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      as={NavLink}
      key={to}
    >
      {children}
    </Link>
  );
}

interface NavigationMenuItemProps extends FlexProps {
  name: string;
  icon?: IconType;
}

function NavigationMenuItem(props: NavigationMenuItemProps) {
  const { name, icon, onClick } = props;

  return (
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: "cyan.400",
        color: "white",
      }}
      onClick={onClick}
    >
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {name}
    </Flex>
  );
}

interface NavigationMenuProps {
  menu: NavMenu;
}

export function NavigationMenu(props: NavigationMenuProps) {
  const { menu } = props;
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <>
      {menu.map((m) => {
        const { icon, name, to } = m;
        const navTo = to ? to : name.toLowerCase();

        return (
          <NavigationLink to={navTo} key={navTo}>
            <NavigationMenuItem icon={icon} name={name} />
          </NavigationLink>
        );
      })}

      <NavigationMenuItem
        name="Back"
        onClick={goBack}
        icon={RiArrowGoBackFill}
      />
    </>
  );
}
