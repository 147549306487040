import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import { Page } from "components";
import { useCostUnits, CostUnit } from "data";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";

export function CostUnitEdit() {
  let { category } = useParams();
  const costUnits = useCostUnits();
  const [costUnit, setCostUnit] = useState<CostUnit | undefined>();

  useEffect(() => {
    if (!category) {
      return;
    }

    costUnits.getByParam(category).then(setCostUnit);
  }, [category, costUnits]);

  const onUpdateCostUnit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    invariant(costUnit, "This should not happen.");

    const formData = new FormData(e.target as HTMLFormElement);

    const category = formData.get("category");
    const regex = formData.get("regex");

    invariant(typeof category === "string", "Invalid category");
    invariant(typeof regex === "string", "Invalid regex");

    console.log(category, regex);

    costUnits.update(costUnit.category, regex, category);
  };

  return (
    <Page menu={[]}>
      <Heading> CostUnitEdit: {category}</Heading>
      <Box>
        <CostUnitUpdateForm
          onSubmit={onUpdateCostUnit}
          category={costUnit?.category}
          regex={costUnit?.regex}
        />
      </Box>
    </Page>
  );
}

interface CostUnitUpdateFormProps {
  category?: string;
  regex?: string;
  onSubmit: (e: ChangeEvent<HTMLFormElement>) => void;
}

function CostUnitUpdateForm(props: CostUnitUpdateFormProps) {
  const { category, regex, onSubmit } = props;

  if (!category || !regex) {
    return null;
  }
  return (
    <form onSubmit={onSubmit}>
      <FormControl>
        <FormLabel>Category</FormLabel>
        <Input type="text" name="category" defaultValue={category} />
        <FormHelperText>
          Create sub categories with "/" as a separator
        </FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Regex</FormLabel>
        <Input type="text" name="regex" defaultValue={regex} />
        <FormHelperText>Used to match transacton comments</FormHelperText>
      </FormControl>
      <Button type="submit">Update</Button>
    </form>
  );
}
