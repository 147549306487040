import { Outlet, Route, Routes } from "react-router-dom";
import { Expenditures } from "./expenditures";

export function ExpendituresRouter() {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="" element={<Expenditures />} />
      </Route>
    </Routes>
  );
}
