import { Routes, Route, Outlet } from "react-router-dom";
import { CostUnitCreate } from "./cost-unit-create";
import { CostUnitEdit } from "./cost-unit-edit";
import { CostUnits } from "./cost-units";

export function CostUnitsRouter() {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="" element={<CostUnits />} />
        <Route path="new" element={<CostUnitCreate />} />
        <Route path=":category" element={<CostUnitEdit />} />
      </Route>
    </Routes>
  );
}
