import { Routes, Route } from "react-router-dom";
import { AccountsRouter } from "./accounts";
import { CostUnitsRouter } from "./cost-units";
import { Dashboard } from "./dashboard";
import { ExpendituresRouter } from "./expenditures";
import { Settings } from "./settings";

export const AppRouter = () => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path="/accounts/*" element={<AccountsRouter />} />
    <Route path="/cost-units/*" element={<CostUnitsRouter />} />
    <Route path="/expenditures/*" element={<ExpendituresRouter />} />
    <Route path="/settings" element={<Settings />} />
  </Routes>
);
