import { Box, Heading } from "@chakra-ui/react";
import { Page } from "components";
import { useExpenditures } from "data";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { ExpenditureBreakdown } from "./expenditure-breakdown";

export function Expenditures() {
  const expenditures = useExpenditures();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const now = moment();

  const category = searchParams.get("category") ?? undefined;

  // const yearParam = searchParams.get("year");
  // if (yearParam) {
  //   now.year(Number(yearParam));
  // }

  // const monthParam = searchParams.get("month");
  // if (monthParam) {
  //   now.month(Number(monthParam) - 1);
  // }

  return (
    <Page menu={[]}>
      <Heading>Expenditures</Heading>
      <Box>{now.toLocaleString()}</Box>

      <Box>
        <ExpenditureBreakdown
          onGotoCostUnit={(selectedCategory) =>
            setSearchParams({ category: selectedCategory })
          }
          units={expenditures.getByCostGroup(category)}
        />
      </Box>
    </Page>
  );
}
