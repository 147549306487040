import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Page } from "components";
import { useAccountDb } from "data";
import invariant from "tiny-invariant";

export function AccountCreateForm() {
  const { add } = useAccountDb();

  const onCreateAccount = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);

    const label = formData.get("label");
    invariant(typeof label === "string" && !!label, "Label is missing");

    const importRegEx = formData.get("importRegEx");
    invariant(
      typeof importRegEx === "string" && !!importRegEx,
      "importRegEx is missing"
    );

    add(label, importRegEx);
  };

  return (
    <Page>
      Create account
      <form onSubmit={onCreateAccount}>
        <FormControl>
          <FormLabel>Account label</FormLabel>
          <Input type="text" name="label" />
          <FormHelperText>
            Label for account, can be changed later.
          </FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel>Import regex</FormLabel>
          <Input
            type="text"
            name="importRegEx"
            defaultValue={`"(?<t>\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d)";(?<t2>"\\d\\d\\.\\d\\d\\.\\d\\d\\d\\d\\");"(?<c>.+)";"(?<a>.+)";"(?<s>.+)"`}
          />
          <FormHelperText>RegEx for parsing imports</FormHelperText>
        </FormControl>
        <Button type="submit">Add</Button>
      </form>
    </Page>
  );
}
