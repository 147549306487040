import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import { Account, useAccountDb, useAccountTransactions } from "data";
import { TransactionList } from "./transaction-list";
import { NavMenu, Page } from "components";
import { RiFileDownloadLine } from "react-icons/ri";

const accountDetailsMenu: NavMenu = [
  { icon: RiFileDownloadLine, name: "import" },
];

export const AccountDetails = () => {
  const { getByLabel } = useAccountDb();
  let { accountId } = useParams();
  const [account, setAccount] = useState<Account | undefined>(undefined);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    getByLabel(accountId).then(setAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (!account || !account.id) {
    return null;
  }

  return (
    <Page menu={accountDetailsMenu}>
      <Heading>Account Info: {account?.label}</Heading>

      <Stack spacing={3}>
        <AccountImportExpression
          importRegEx={account.importRegEx}
          accountId={account.id}
        />
        <AccountTransactions accountId={account.id} />
      </Stack>
    </Page>
  );
};

interface AccountImportExpressionProps {
  importRegEx: string;
  accountId: number;
}

const AccountImportExpression = (props: AccountImportExpressionProps) => {
  const { accountId, importRegEx } = props;

  const { update } = useAccountDb();

  const onUpdateImportExpression = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const id = Number(formData.get("id"));
    invariant(typeof id === "number", "invalid account id");
    const importRegEx = formData.get("importRegEx");

    invariant(
      typeof importRegEx === "string" && !!importRegEx,
      "Missing parse expression"
    );
    update(id, { importRegEx });
  };

  if (!importRegEx) {
    return null;
  }

  return (
    <form onSubmit={onUpdateImportExpression}>
      <Input type="number" hidden defaultValue={accountId} name="id" />

      <FormControl>
        <FormLabel>Update import regex</FormLabel>
        <Input defaultValue={importRegEx} type="text" name="importRegEx" />
        <FormHelperText>RegEx for parsing imports</FormHelperText>
      </FormControl>
      <Button type="submit">Update</Button>
    </form>
  );
};

interface AccountTransactionsProps {
  accountId: number;
}

const AccountTransactions = (props: AccountTransactionsProps) => {
  const { accountId } = props;
  const { transactions } = useAccountTransactions({ accountId });

  return (
    <Box>
      <TransactionList transactions={transactions} />
    </Box>
  );
};
