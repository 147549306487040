import { mathUtils } from "utils";
import { useAllAccountsTransactions } from "./account-transactions";
import { useCostUnits } from "./cost-units";
import { AccountTransaction } from "./models";

interface CostGroup {
  category: string;
  transactions: AccountTransaction[];
}

interface ExpendituresByCostUnit {
  category: string;
  sum: number;
}

export function useExpenditures() {
  const allAccountsTransactions = useAllAccountsTransactions();
  const { all: allCostUnits } = useCostUnits();

  function getTransactionsByCostUnit(): CostGroup[] {
    const allTransactions = allAccountsTransactions.byUser();

    const transactionsByCostUnit: { [p: string]: AccountTransaction[] } = {};

    for (const transaction of allTransactions) {
      for (const costUnit of allCostUnits) {
        const tester = new RegExp(costUnit.regex);
        if (tester.test(transaction.comment)) {
          if (!transactionsByCostUnit[costUnit.category]) {
            transactionsByCostUnit[costUnit.category] = [];
          }

          transactionsByCostUnit[costUnit.category].push(transaction);
        }
      }
    }

    return Object.entries(transactionsByCostUnit).map((entry) => {
      const [category, transactions] = entry;
      return { category, transactions };
    });
  }

  function getExpenditureByCostUnit(
    category?: string
  ): ExpendituresByCostUnit[] {
    const transactionsByCostUnit = getTransactionsByCostUnit().filter((f) =>
      category ? f.category === category : true
    );

    const expenditures = transactionsByCostUnit.map((c) => {
      const { category, transactions } = c;

      const sum = transactions.reduce((p, c) => p + c.amount, 0);

      return { category, sum: mathUtils.round(sum, 2) };
    });

    return expenditures;
  }

  function getByCostGroup(category?: string): ExpendituresByCostUnit[] {
    const expenditureByCostUnit = getExpenditureByCostUnit().filter((f) =>
      category ? f.category.startsWith(category) : true
    );

    const costByGroup: { [p: string]: number } = {};

    console.log(category);

    for (const costGroup of expenditureByCostUnit) {
      const { category, sum } = costGroup;

      const currentCategory = !category ? category.split("/")[0] : category;

      if (!costByGroup[currentCategory]) {
        costByGroup[currentCategory] = 0;
      }

      costByGroup[currentCategory] += sum;
    }

    return Object.entries(costByGroup).map((e): ExpendituresByCostUnit => {
      const [category, sum] = e;

      return { category, sum };
    });
  }

  return {
    getTransactionsByCostUnit,
    getExpenditureByCostUnit,
    getByCostGroup,
  };
}
