import { Heading } from "@chakra-ui/react";
import { useAuth } from "auth";
import { JSONPretty, Page } from "components";

export const Settings = () => {
  const { profile } = useAuth();
  return (
    <Page>
      <Heading>Profile</Heading>
      <JSONPretty j={profile} />
    </Page>
  );
};
