import {
  Heading,
  IconButton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { CostUnit, useCostUnits } from "data";
import { NavMenu, Page } from "components";
import { RiDeleteBin2Line, RiEdit2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const navMenu: NavMenu = [{ name: "new" }];

export function CostUnits() {
  const navigate = useNavigate();
  const costUnits = useCostUnits();
  const onDelete = (u: CostUnit) => costUnits.remove(u);
  const onEdit = (category: string) => {
    const to = `${category.replace("/", "-")}`;

    navigate(to);
  };

  return (
    <Page menu={navMenu}>
      <Heading>Cost units</Heading>

      <CostUnitList
        costUnits={costUnits.all}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    </Page>
  );
}

interface CostUnitListProps {
  costUnits: CostUnit[];
  onDelete: (u: CostUnit) => void;
  onEdit: (category: string) => void;
}

function CostUnitList(props: CostUnitListProps) {
  const { costUnits, onDelete, onEdit } = props;

  return (
    <TableContainer>
      <Table variant="simple">
        <TableCaption>Cost units categories, used to group expenditures</TableCaption>

        <Thead>
          <Tr>
            <Th>Category</Th>
            <Th>RegEx</Th>
            <Th>Options</Th>
          </Tr>
        </Thead>
        <Tbody>
          {costUnits.map((u) => (
            <Tr
              key={u.category}
              onClick={() => onEdit(u.category)}
              cursor="pointer"
              userSelect="none"
            >
              <Td>{u.category}</Td>
              <Td>{u.regex}</Td>
              <Td>
                <IconButton
                  onClick={() => onEdit(u.category)}
                  aria-label="Delete cost unit"
                  icon={<RiEdit2Line />}
                />
                <IconButton
                  onClick={() => onDelete(u)}
                  aria-label="Delete cost unit"
                  icon={<RiDeleteBin2Line />}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
