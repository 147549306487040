import { Outlet, Route, Routes } from "react-router-dom";
import { AccountCreateForm } from "./account-create";
import { AccountList } from "./account-list";
import { AccountDetails } from "./account-details";
import { TransactionImport } from "./transaction-import";

export function AccountsRouter() {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="" element={<AccountList />} />
        <Route path=":accountId" element={<AccountDetails />} />
        <Route path=":accountId/import" element={<TransactionImport />} />
        <Route path="new" element={<AccountCreateForm />} />
      </Route>
    </Routes>
  );
}
