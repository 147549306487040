import { Box, Grid, GridItem } from "@chakra-ui/react";
import { Footer, Header, NavigationMenu, NavMenu } from "components";
import React from "react";
import {
  RiHome2Line,
  RiSafe2Fill,
  RiPriceTag3Fill,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";

interface LayoutProps {
  children: React.ReactNode;
}

const linkItems: NavMenu = [
  { name: "Home", icon: RiHome2Line, to: "/" },
  { name: "Accounts", icon: RiSafe2Fill },
  { name: "Cost units", icon: RiPriceTag3Fill, to: "cost-units" },
  { name: "Expenditures", icon: RiMoneyDollarCircleLine },
];

export const LayoutProvider = ({ children }: LayoutProps) => {
  return (
    <Grid
      templateAreas={`"header header"
                "nav main"
                "nav footer"`}
      gridTemplateRows={"75px 1fr 30px"}
      gridTemplateColumns={"200px 1fr"}
      height="100vh"
      gap="1"
      color="blackAlpha.700"
      fontWeight="bold"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "primary",
          borderRadius: "24px",
        },
      }}
    >
      <GridItem pl="2" bg="orange.300" area={"header"}>
        <Header linkItems={linkItems} />
      </GridItem>
      <GridItem pl="2" bg="pink.300" area={"nav"} />
      <GridItem pl="2" bg="green.300" area={"main"} />
      <GridItem pl="2" bg="blue.300" area={"footer"}>
        <Footer />
      </GridItem>
      {children}
    </Grid>
  );
};

interface PageProps {
  menu?: NavMenu;
  children: React.ReactNode;
}

export const Page = (props: PageProps) => {
  const { menu, children } = props;

  return (
    <>
      {menu && (
        <Box gridArea={"nav"}>
          <NavigationMenu menu={menu ?? []} />
        </Box>
      )}
      <Box gridArea={"main"} padding="2" overflow="auto">
        {children}
      </Box>
    </>
  );
};
