import {
  Auth0Provider,
  Auth0ProviderOptions,
  useAuth0,
} from "@auth0/auth0-react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import invariant from "tiny-invariant";
export {
  type Auth0ProviderOptions,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
interface Profile {
  userId: string;
}

const getUserId = (sub: string | undefined) => {
  invariant(typeof sub === "string", "user missing sub");

  const match = /auth0\|(?<id>.+)/.exec(sub);

  const id = match?.groups?.["id"];
  invariant(typeof id === "string", "user missing id");

  return id;
};

export const useAuth = (): { profile: Profile; userId: string } => {
  const { user } = useAuth0();
  invariant(user !== undefined, "user missing");

  const { sub, ...rest } = user;

  const userId = getUserId(sub);

  return { profile: { userId, ...rest }, userId };
};

interface AuthProviderProps extends Auth0ProviderOptions {
  children: React.ReactNode;
}

export const AuthProvider = ({ children, ...props }: AuthProviderProps) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

export function Logout() {
  const { logout } = useAuth0();

  return (
    <Button onClick={() => logout({ returnTo: window.location.origin })}>
      Logout
    </Button>
  );
}
