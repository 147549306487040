import {
  Stack,
  Input,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Icon,
  Button,
  Heading,
} from "@chakra-ui/react";
import { ChangeEvent, createRef, useEffect, useState } from "react";
import { RiFileUploadFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import invariant from "tiny-invariant";
import {
  Account,
  ImportTransaction,
  useAccountDb,
  useTransactionsImport,
} from "data";
import { fileUtils, transactionUtils } from "utils";
import { TransactionList } from "./transaction-list";
import { Page } from "components";

export const TransactionImport = () => {
  const { getByLabel } = useAccountDb();
  let { accountId } = useParams();
  const { importTransactions } = useTransactionsImport();
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [transactions, setTransactions] = useState<ImportTransaction[]>([]);

  const importNameRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (!accountId) {
      return;
    }

    getByLabel(accountId).then(setAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  if (!account) {
    return null;
  }

  const importParserMask = account.importRegEx;
  const id = account.id!;

  const fileImporter = async (e: ChangeEvent<HTMLInputElement>) => {
    invariant(e.target.files, "A file must be selected");
    invariant(importNameRef.current, "Failed to set import name");

    const file = e.target.files.item(0)!;

    importNameRef.current.value = file.name;

    const data = await fileUtils.readFileAsText(file);
    const transactions = transactionUtils.text2Transactions(
      data,
      importParserMask
    );

    setTransactions(transactions);
  };

  const onImportTransaction = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    invariant(
      typeof importNameRef.current?.value === "string",
      "Failed to set import name"
    );

    importTransactions({
      accountId: id,
      importRef: importNameRef.current?.value,
      transactions,
    });
  };

  return (
    <Page menu={[]}>
      <form onSubmit={onImportTransaction}>
        <Heading>Transaction Import</Heading>
        <Stack>
          <Input
            value={JSON.stringify(transactions)}
            name="transaction-data"
            hidden
            readOnly
          />
          <Input hidden name="import-name" ref={importNameRef} />
          <FormControl>
            <FormLabel htmlFor="writeUpFile">
              File: {transactions.length}
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon as={RiFileUploadFill} />}
              />
              <Input type="file" onChange={fileImporter} />
            </InputGroup>
          </FormControl>

          <FormControl>
            <Button type="submit" disabled={transactions.length === 0}>
              Import
            </Button>
          </FormControl>
          <TransactionList transactions={transactions} />
        </Stack>
      </form>
    </Page>
  );
};
