import { useAuth } from "auth";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "./db";

export const useAccountTransactions = ({
  accountId,
}: {
  accountId: number;
}) => {
  const transactions = useLiveQuery(() => {
    return db.accountTransactions.where({ accountId }).toArray();
  }, [accountId]);

  return { transactions: transactions ?? [] };
};

export const useAllAccountsTransactions = () => {
  const { userId } = useAuth();

  const userTransactions = useLiveQuery(
    () => db.accountTransactions.where({ userId }).toArray(),
    [userId]
  );

  function byUser() {
    return userTransactions ?? [];
  }

  return { byUser };
};
