import { useLiveQuery } from "dexie-react-hooks";
import { useAuth } from "auth";
import { db } from "./db";

export const useAccountDb = () => {
  const { userId } = useAuth();

  const accounts = useLiveQuery(() => db.accounts.where({ userId }).toArray());
  const add = (label: string, importRegEx: string) => {
    db.accounts.add({ label, userId, importRegEx });
  };

  const getByLabel = (label: string) => {
    return db.accounts.get({ label });
  };

  const update = (id: number, changes: { [k: string]: any }) => {
    return db.accounts.update(id, changes);
  };

  return { accounts, add, getByLabel, update };
};
