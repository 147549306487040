export const JSONPretty = (props: { j: any; debug?: boolean }) => {
  const { j, debug } = props;
  const str = JSON.stringify(j, null, 2);

  if (debug) {
    console.log(str);
  }

  return (
    <div>
      <pre>
        <code>{str}</code>
      </pre>
    </div>
  );
};
