import { Flex, Box } from "@chakra-ui/react";
import { Logout } from "auth";
import { NavigationMenu, NavMenu, SettingsMenu } from "./navigation";

interface HeaderProps {
  linkItems: NavMenu;
}

export function Header(props: HeaderProps) {
  const { linkItems } = props;

  return (
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
    >
      <Box>Moffan MiniAccount</Box>
      <NavigationMenu menu={linkItems} />
      <SettingsMenu />
      <Logout />
    </Flex>
  );
}
