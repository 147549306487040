import { ListItem, UnorderedList } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useAccountDb } from "data";
import { NavMenu, Page } from "components";
import { RiAddCircleLine } from "react-icons/ri";

const linkItems: NavMenu = [{ name: "New", icon: RiAddCircleLine }];

export const AccountList = () => {
  const { accounts } = useAccountDb();

  return (
    <Page menu={linkItems}>
      <UnorderedList>
        {accounts?.map((a) => (
          <ListItem key={a.id}>
            <NavLink to={a.label}>{a.label}</NavLink>
          </ListItem>
        ))}
      </UnorderedList>
    </Page>
  );
};
