import { AppRouter } from "./routes";
import {
  Auth0ProviderOptions,
  AuthProvider,
  withAuthenticationRequired,
} from "auth";
import { BrowserRouter } from "react-router-dom";
import { LayoutProvider } from "components";
import { ChakraProvider } from "@chakra-ui/react";

const LayoutWithAuthentication = withAuthenticationRequired(LayoutProvider);

const config: Auth0ProviderOptions = {
  domain: "moff.eu.auth0.com",
  clientId: "e5YYcv5g6D01Mav0oCvGy1X6kLefGpVT",
  redirectUri: window.location.origin,
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <AuthProvider {...config}>
          <LayoutWithAuthentication>
            <AppRouter />
          </LayoutWithAuthentication>
        </AuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
