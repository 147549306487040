import Dexie, { Table } from "dexie";
import {
  Account,
  AccountTransaction,
  CostUnit,
  TransactionImport,
} from "./models";

class Storage extends Dexie {
  accounts!: Table<Account>;
  transactionImports!: Table<TransactionImport>;
  accountTransactions!: Table<AccountTransaction>;
  costUnits!: Table<CostUnit>;

  constructor() {
    super("miniAccount");
    this.version(3).stores({
      accounts: "++id, label, userId",
      accountTransactions: "++id, amount, comment,timestamp, accountId, userId",
      costUnits: "&category, regex, userId, tags",
      transactionImports: "&importRef, accountId, userId",
    });
  }
}

export const db = new Storage();
