import { useLiveQuery } from "dexie-react-hooks";
import { urlUtils } from "utils";
import { db } from "./db";
import { CostUnit } from "./models";

function getTagsFromCategory(category: string) {
  const tags: string[] = [];
  let currentTag = "";
  for (const tag of category.split("/")) {
    currentTag = currentTag ? `${currentTag}/${tag}` : tag;
    tags.push(currentTag);
  }

  return tags;
}

export const useCostUnits = () => {
  const costUnitDb = db.costUnits;

  const all =
    useLiveQuery(() => costUnitDb.orderBy("category").toArray(), []) ?? [];

  function add(category: string, regex: string) {
    const tags: string[] = getTagsFromCategory(category);

    costUnitDb.add({ category: category.toLowerCase(), regex, tags });
  }

  function remove(costUnit: CostUnit) {
    costUnitDb.delete(costUnit.category);
  }

  async function getByParam(categoryParam: string) {
    const category = urlUtils.convertFormUrlParams(categoryParam);
    const a = await costUnitDb.get({ category });

    return a;
  }

  function update(
    existingCategory: string,
    regex: string,
    newCategory?: string
  ) {
    const category = newCategory ?? existingCategory;

    const tags: string[] = getTagsFromCategory(category);

    costUnitDb.update(category, { category, regex, tags });
  }

  return { add, all, remove, getByParam, update };
};
