export function readFileAsText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target) {
        return reject("No file data");
      }

      resolve(e.target.result as string);
    };

    reader.readAsText(file);
  });
}
