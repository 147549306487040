import { ImportTransaction } from "data";
import { splitLines } from "./string.utils";

export function text2Transactions(data: string, regexPattern: string) {
  const transactionMatcher = new RegExp(regexPattern);
  const lines = splitLines(data);

  const transactions: ImportTransaction[] = [];

  for (const line of lines.filter((s) => !!s)) {
    const lineMatch = transactionMatcher.exec(line);
    if (!lineMatch || !lineMatch.groups) {
      throw new Error(`Invalid data: {${line}}`);
    }

    const { t: timestamp, c: comment, a: amount } = lineMatch.groups;
    const tempAmount = amount.replace(".", "").replace(",", ".");

    if (isNaN(Number(tempAmount))) {
      throw new Error(`Not a valid amount: {${line}}`);
    }

    transactions.push({
      timestamp,
      comment,
      amount: Number(tempAmount),
    });
  }

  return transactions;
}
