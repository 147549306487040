import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Page } from "components";
import { useCostUnits } from "data";
import invariant from "tiny-invariant";

export function CostUnitCreate() {
  const costUnits = useCostUnits();

  const onAddCostUnit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const category = formData.get("category");
    invariant(
      typeof category === "string" && !!category,
      "Category is missing"
    );

    const regex = formData.get("regex");
    invariant(typeof regex === "string" && !!regex, "Regex is missing");

    costUnits.add(category, regex);
  };

  return (
    <Page>
      <Box>
        <form onSubmit={onAddCostUnit}>
          <FormControl>
            <FormLabel>Category!!!</FormLabel>
            <Input type="text" name="category" />
            <FormHelperText>
              Sub categories can be created by using "/" as a separator
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel>RegEx</FormLabel>
            <Input type="text" name="regex" />
          </FormControl>
          <Button type="submit">Add new</Button>
        </form>
      </Box>
    </Page>
  );
}
