import invariant from "tiny-invariant";
import { useAuth } from "auth";
import { db } from "./db";
import { ImportTransaction } from "./models";

export const useTransactionsImport = () => {
  const { profile } = useAuth();
  const { userId } = profile;

  async function importTransactions(input: {
    transactions: ImportTransaction[];
    accountId: number;
    importRef: string;
  }) {
    const { transactions, accountId, importRef } = input;

    invariant(transactions.length > 0, "No transactions to import");
    const existingImports = await db.transactionImports
      .filter((i) => i.importRef === importRef)
      .count();

    if (existingImports > 0) {
      console.log("Import allready exist, exiting");

      return;
    }

    db.transaction(
      "rw",
      db.transactionImports,
      db.accountTransactions,
      async () => {
        db.accountTransactions.bulkAdd(
          transactions.map((t: ImportTransaction) => ({
            accountId,
            amount: t.amount,
            comment: t.comment,
            timestamp: t.timestamp,
            userId,
          }))
        );

        db.transactionImports.add({ accountId, importRef, userId });
      }
    )
      .then(() => {
        console.log("Transaction committed");
      })
      .catch((err) => {
        console.error(err.stack);
      });
  }

  return { importTransactions };
};
