import {
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";

interface ExpenditureBreakdownProps {
  units: Array<{ category: string; sum: number }>;
  onGotoCostUnit: (category: string) => void;
}

export const ExpenditureBreakdown = (props: ExpenditureBreakdownProps) => {
  const { onGotoCostUnit, units } = props;

  return (
    <TableContainer>
      <Table variant="simple">
        <TableCaption>Expenditure by cost unit</TableCaption>

        <Thead>
          <Tr>
            <Th>Category</Th>
            <Th>Sum</Th>
          </Tr>
        </Thead>
        <Tbody>
          {units.map((c) => {
            const { category, sum } = c;

            return (
              <Tr
                key={category}
                onClick={() => onGotoCostUnit(category)}
                cursor="pointer"
              >
                <Td>{category}</Td>
                <Td>{sum}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
